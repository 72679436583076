const contactArr = [
  {
    photo: "/images/lectors/tereza.png",
    name: "Tereza Vaňková",
    interest: "Founder & Partnership",
    mail: "terka@reactgirls.com",
    linkedIn: "https://www.linkedin.com/in/tereza-vankova/",
    facebook: "https://www.facebook.com/tereza.vankova.7",
    twitter: "https://twitter.com/vanektereza",
    hideFacebook: true,
    hideTwitter: true,
  },
  {
    photo: "/images/lectors/zuzka-vlach.png",
    name: "Zuzka Vlachovská",
    interest: "CEO",
    mail: "zuzka.v@reactgirls.com",
    linkedIn: "https://www.linkedin.com/in/zuzanavlachovska/",
    hideFacebook: true,
    hideTwitter: true,
  },
];

export default contactArr;
