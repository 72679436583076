const socialArr = [
  {
    link: "https://www.linkedin.com/company/reactgirlsprague/",
    icon: "/images/icons/linkedIn.svg",
  },
  {
    link: "https://www.facebook.com/ReactGirlsPrague",
    icon: "/images/icons/facebook.svg",
  },
  {
    link: "https://www.instagram.com/reactgirlsprague/",
    icon: "/images/icons/instagram.svg",
  },
  {
    link: "https://www.youtube.com/channel/UCO8WGBzS6J00zlXroeTf90g",
    icon: "/images/icons/youtube.svg",
  },
];

export default socialArr;
